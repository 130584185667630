// Select all elements with the class 'animation'
const the_animation = document.querySelectorAll(".animation");

// Create a new Intersection Observer
const observer = new IntersectionObserver(
  (entries) => {
    // For each entry
    entries.forEach((entry) => {
      // If the entry is intersecting (visible in the viewport)
      if (entry.isIntersecting) {
        // Add the 'scroll-animation' class to the target element
        entry.target.classList.add("scroll-animation");
      } else {
        // If the entry is not intersecting (not visible), remove the 'scroll-animation' class
        entry.target.classList.remove("scroll-animation");
      }
    });
  },
  // Set the threshold for the Intersection Observer to 0.5,
  // meaning at least 35% of the element needs to be visible for it to be considered intersecting
  { threshold: 0 }
);

// For each element in the 'the_animation' NodeList
for (let i = 0; i < the_animation.length; i++) {
  const elements = the_animation[i];

  // Start observing the element for intersection with the viewport
  observer.observe(elements);
}